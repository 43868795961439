/* src/components/Header.css */
.header-transparent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
}

.header-transparent .icon-container a {
    color: inherit;
    text-decoration: none;
    margin-right: 40px;
}

.header-transparent .icon {
    width: 32px;
    height: 32px;
}

.header-transparent .icon img {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .header-transparent {
        flex-direction: column; /* Stack items vertically */
        padding: 10px; /* Reduce padding for smaller screens */
    }

    .header-transparent .icon-container a {
        margin-right: 20px; /* Reduce margin for smaller screens */
    }

    .header-transparent .icon {
        width: 24px; /* Smaller icon size for mobile devices */
        height: 24px; /* Smaller icon size for mobile devices */
    }
}