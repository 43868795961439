/* src/components/MainContent.css */
.main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
    text-align: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.emoji-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    font-size: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    animation: scroll 20s linear infinite;
    z-index: -1;
    opacity: 0.1;
}

.emoji {
    padding: 20px;
}

@keyframes scroll {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}

.square-picture {
    width: 300px;
    height: 300px;
    border: 2px solid black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    object-fit: cover;
}

.main-content p {

    margin: 10px 0;
    font-size: 1.1em;
    color: #444;
}

.deal-description {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
    text-align: justify;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-width: 600px;
    margin: 0 20px;
    text-align: left;
}

.marquee-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    margin-top: 20px;
}

.marquee-container hr {
    border: 1px solid black;
    margin: 0;
}

marquee {
    font-size: 1.2em;
    color: black;
    padding: 10px 0;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .main-content {
        margin-top: 100px;

    }
    
    .square-picture {
        width: 300px;
        height: 300px;
    }
    
    .deal-description {
        font-size: 0.9em;
        margin: 0 10px;
    }
    
    .marquee-container {
        font-size: 1em;
    }

    .emoji-background {
        font-size: 40px;
    }
}